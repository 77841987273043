import { XMarkIcon } from '@heroicons/react/24/outline';
import { AuthContext } from "../../store/AuthContext";
import { useContext } from "react";
import { Link } from "react-router-dom";

const MobileSidebar = ({ menuVisible, setMenuVisible }) => {
    const authContext = useContext(AuthContext);

    return (
        <div className={`fixed top-0 right-0 w-4/5 h-full bg-white z-[15] transition-transform transform ${menuVisible ? 'translate-x-0' : 'translate-x-full'} ease-in-out duration-300`}>
            {/* Header with Menu title and Close icon */}
            <div className="flex items-center justify-between px-6 py-4 border-b border-gray-200 shadow-sm">
                <h2 className="text-3xl font-semibold min-w-min">Menu</h2>
                <XMarkIcon
                    className="cursor-pointer"
                    width={32}
                    color="black"
                    onClick={() => setMenuVisible(false)}
                />
            </div>
            {/* Menu Items */}
            <div className="flex flex-col items-start px-6 mt-10 space-y-6 font-sans font-normal">
                <Link to="/dashboard" className="text-lg text-black">Dashboard</Link>
                <Link to="/rag" className="text-lg text-black">RAG</Link>
                <Link to="/legal-assistant" className="text-lg text-black">Legal Assistant</Link>
                <button className="text-lg text-black" onClick={authContext.logout}>Logout</button>
            </div>
        </div>
    );
};

export default MobileSidebar;