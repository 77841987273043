import { useContext } from "react";
import { AuthContext } from "../store/AuthContext";
import { Navigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { getToken } from "./util";

const ProtectedRoute = ({ children }) => {
    const authContext = useContext(AuthContext);
    const token = getToken();

    if (!token) {
        return <Navigate to="/login" />;
    }

    return children;
}

export default ProtectedRoute;