const PublicLayout = ({ children }) => {
    return (
        <div className="w-screen h-screen">
            <div className="flex w-full h-full">
                <div className="w-full h-full px-6 py-24 md:px-12 md:w-1/2">
                    {children}
                </div>
                <div className="relative hidden w-1/2 h-full px-12 py-24 space-y-10 md:flex md:flex-col bg-dinaric-landscape">
                    <h2 className="font-sans text-5xl font-normal text-white">
                        Dinaric Elevation
                    </h2>
                    <p className="mt-4 font-sans text-lg font-light text-white lg:mt-6 lg:text-3xl">
                        Elevate your business with AI agents and tools.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default PublicLayout;