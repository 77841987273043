import SidebarLayout from "../../components/layouts/SidebarLayout";
import { useState } from "react";
import Select from "react-select"
import PrimaryButton from "../../components/buttons/PrimaryButton";
import legalAssistantApi from "../../services/apis/legalAssistantApi";
import { isSuccess } from "../../shared/util";

const LegalAssistant = () => {

    const legalAssistantVersions = [
        { value: 1, label: "Version 1" },
        { value: 2, label: "Version 2" },
    ];

    const [formSubmissionInProgress, setformSubmissionInProgress] = useState(false);
    const [errorMessages, setErrorMessages] = useState(null);
    const [error, setError] = useState(false);

    const [version, setVersion] = useState(1);
    const [question, setQuestion] = useState("");

    const [response, setResponse] = useState("");
    const [references, setReferences] = useState([]);

    const legalAssistantService = legalAssistantApi();


    const handleFormSubmission = async () => {
        setformSubmissionInProgress(true);
        setErrorMessages(null);
        setError(false);

        if (question.length === 0) {
            setErrorMessages('Issue is required');
            setformSubmissionInProgress(false);
            return;
        }

        let createLegalAsisstantResponse = null;
        try {
            createLegalAsisstantResponse = await legalAssistantService.post({
                question: question,
                version: version,
            });
        } catch (error) {
            setError(true);
            setformSubmissionInProgress(false);
            return;
        }

        if (!isSuccess(createLegalAsisstantResponse.status)) {
            const errorMessage = createLegalAsisstantResponse.data && createLegalAsisstantResponse.data.message ? createLegalAsisstantResponse.data.message
                : 'Something went wrong. Please contact system administrator.';

            setError(true);
            setErrorMessages(errorMessage);
            setformSubmissionInProgress(false);
            return;
        }

        setformSubmissionInProgress(false);
        setResponse(createLegalAsisstantResponse.data.answer);
        if (createLegalAsisstantResponse.data.context) {
            setReferences(createLegalAsisstantResponse.data.context);
        }
    }

    const handleRefresh = () => {
        setResponse("");
        setReferences([]);
        setQuestion("");
        setVersion(1);
    }

    const formatContextDocument = (document) => {
        const template = `
            {law_name} - Član {article_number}. {article_title}
            <br><br>
            <p class='whitespace-pre-line'> {article_text} </p>
        `

        let formatedDocument = template.replace('{law_name}', document.metadata.law_name)
            .replace('{article_number}', document.metadata.article_number)
            .replace('{article_title}', document.metadata.article_title)
            .replace('{article_text}', document.page_content.replace(document.metadata.article_title + ": ", ""))

        return formatedDocument
    }

    return (
        <SidebarLayout title="Legal Assistant">
            <div className="flex flex-col space-y-10 max-w-[720px]">
                <div className="font-sans">
                    <p className="text-lg font-semibold">How to Use the Legal Assistant</p>
                    <ol className="flex flex-col mt-4 ml-4 space-y-4 list-decimal">
                        <li>
                            <span className="font-medium">Enter Your Legal Issue:</span ><span className="font-normal"> In the text box provided, describe your legal problem or question in as much detail as possible.</span >
                        </li>
                        <li>
                            <span className="font-medium">Generate Legal Advice:</span><span className="font-normal">Once you've submitted your query, the system will analyze the information and generate advice, including references to relevant laws, acts, and specific articles.</span >
                        </li>
                        <li>
                            <span className="font-medium">Review Legal References:</span><span className="font-normal"> The system will display the legal advice along with citations from the appropriate laws and articles. You can use this information to better understand your legal standing.</span >
                        </li>
                    </ol>
                </div>
                {response === "" ?
                    (<form className="flex flex-col space-y-4 font-sans text-center md:text-start" onSubmit={(e) => { e.preventDefault() }}>
                        <Select
                            className="w-1/3"
                            options={legalAssistantVersions}
                            isSearchable={false}
                            value={legalAssistantVersions.filter(function (option) {
                                return option.value === version;
                            })}
                            onChange={option => setVersion(option.value)}
                        />

                        <textarea
                            name="question"
                            placeholder="Type your issue here..."
                            className="w-full p-3 text-gray-900 rounded-md resize-none drop-shadow-sm focus:drop-shadow-none placeholder:silver600 "
                            rows={6}
                            value={question.value}
                            onChange={(e) => { setQuestion(e.target.value) }}
                        />

                        <PrimaryButton
                            text="Submit"
                            type="submit"
                            onClick={handleFormSubmission}
                            spinning={formSubmissionInProgress}
                            className="w-1/2"
                        />

                        <div className="flex flex-col justify-center space-y-1">
                            {error && <span className="text-red-600">Error submiting form</span>}
                            {errorMessages && <span className="text-red-600">{errorMessages}</span>}
                        </div>
                    </form>) :
                    (<div>
                        <div className="bg-[#FFF9F5] p-5 mb-5 rounded-md">
                            <span className="font-bold underline">Issue</span>
                            <p className="mt-5 whitespace-pre-line">{question}</p>
                        </div>

                        <div className="bg-[#EEF9FF] p-5 mb-5 rounded-md">
                            <span className="font-bold underline">Response</span>
                            <div className="mt-5" dangerouslySetInnerHTML={{ __html: response }}></div>
                        </div>

                        <div className="bg-[#F6FFF8] p-5 mb-5 rounded-md">
                            <span className="font-bold underline">References</span>
                            <ul className="flex flex-col mt-4 ml-4 space-y-4 list-disc">
                                {references.map(reference => (
                                    <li key={reference.metadata.article_id} dangerouslySetInnerHTML={{ __html: formatContextDocument(reference) }}></li>
                                ))}
                            </ul>
                        </div>

                        <PrimaryButton
                            text="Refresh"
                            type="button"
                            onClick={handleRefresh}
                            className="w-1/2"
                        />
                    </div>)}
            </div>
        </SidebarLayout>

    );
}

export default LegalAssistant;