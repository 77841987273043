import { createContext, useEffect, useState } from "react";
import { isSuccess, removeToken, setToken, } from "../shared/util";
import userApi from "../services/apis/userApi";
import sessionApi from "../services/apis/sessionApi";

export const AuthContext = createContext({
    session: null,
    user: null,
    companyUuid: null,
    companyName: null,
});

const AuthContextProvider = ({ children }) => {
    const [session, setSession] = useState(null);
    const [user, setUser] = useState(null);
    const [companyUuid, setCompanyUuid] = useState(null)
    const [companyName, setCompanyName] = useState(null)

    const sessionService = sessionApi();

    useEffect(() => {
        if (session) {
        } else {
            setUser(null);
        }
    }, [session]);

    const login = (token) => {
        logout();
        setSession(token);
        setToken(token);
        fetchClientData();
    }

    const logout = () => {
        setSession(null);
        removeToken();
    }

    const fetchClientData = async () => {
        const getClientResponse = await sessionService.getAll();
        setCompanyName(getClientResponse.data.company_name);
        setCompanyUuid(getClientResponse.data.company_uuid);
        setUser(getClientResponse.data.email)
    }

    const value = {
        session: session,
        user: user,
        companyUuid: companyUuid,
        companyName: companyName,
        setSession: setSession,
        setUser: setUser,
        login: login,
        logout: logout,
        fetchClientData: fetchClientData
    };

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
}

export default AuthContextProvider;