import SidebarLayout from "../../components/layouts/SidebarLayout";
import { AuthContext } from "../../store/AuthContext";
import { CopyBlock } from 'react-code-blocks';
import { useContext } from "react";


const Integration = () => {
    const apiUrl = process.env.REACT_APP_API_URL
    const authContext = useContext(AuthContext);
    const code = `
        <script>
        let company_uuid = "${authContext.companyUuid}"
        let endpoint_url = "${apiUrl}/rag"
        </script>
        
        <script src="https://storage.dinaric.io/scripts/chat-widget.js"></script>
        `;

    return (
        <SidebarLayout title="Integration">
            <div className="max-w-full sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl">
                <p className="mb-8">To integrate a chatbot on your site copy the code blow and include it inside your root html file.</p>
                <CopyBlock
                text={code}
                language="javascript"
                showLineNumbers={false}
                />
            </div>
           
        </SidebarLayout>

    );
}

export default Integration;