import axios from "axios";
import { toast } from "react-toastify";

export const showError = (message) => {
    toast.error(message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: null,
        theme: "light",
    });
}

export const getPath = (url) => {
    var reg = /.+?:\/\/.+?(\/.+?)(?:#|\?|$)/;
    return reg.exec(url)[1];
}

export const setToken = (token) => {
    removeToken();
    localStorage.setItem("elvt-token", token);
}

export const removeToken = () => {
    localStorage.removeItem("elvt-token");
}

export const getToken = () => {
    const token = localStorage.getItem("elvt-token");
    if (!token || token === "undefined")
        return null;

    return token;
}

export const isSuccess = (code) => code >= 200 && code < 300;