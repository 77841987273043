/** @type {import('tailwindcss').Config} */
module.exports = {
    content: [
        "./src/pages/**/*.{js,ts,jsx,tsx,mdx}",
        "./src/components/**/*.{js,ts,jsx,tsx,mdx}",
        "./src/app/**/*.{js,ts,jsx,tsx,mdx}",
    ],
    theme: {
        screens: {
            'sm': '640px',
            // => @media (min-width: 640px) { ... }

            'md': '768px',
            // => @media (min-width: 768px) { ... }

            'lg': '1024px',
            // => @media (min-width: 1024px) { ... }

            'xl': '1280px',
            // => @media (min-width: 1280px) { ... }

            '2xl': '1536px',
            // => @media (min-width: 1536px) { ... }
            '3xl': '1920px',
        },
        extend: {
            backgroundImage: {
                "gradient-radial": "radial-gradient(var(--tw-gradient-stops))",
                "gradient-conic":
                    "conic-gradient(from 180deg at 50% 50%, var(--tw-gradient-stops))",
                'dinaric-landscape': "url('/public/dinaric-bg.png')",
            },
            fontFamily: {
                sans: ['Helvetica Neue', 'Arial', 'sans-serif'],
            },
            colors: ({ colors }) => ({
                'brand': '#1C7AAF',
                'brand-100': '#1A5272',
                'brand-500': '#E95370',
                'brand-600': '#C81B3F',
                'brand-800': '#B01639',
                'dark': '#181818',
                'dark-700': '#350017',
                'dark-400': '#464646',
                'our-yellow': '#FFF8EA',
            }),
        },
    },
    plugins: [
        require('@tailwindcss/forms'),
        require('@tailwindcss/typography'),
    ],
};
