import { useState } from 'react';
import colors from '../../shared/colors';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';

const InputField = ({ isValid = true, name, placeholder, value, onChange, onBlur, onKeyDown, type, icon, className, infoMessage, errorMessage, successMessage, disabled }) => {
    const [showPassword, setShowPassword] = useState(false);

    const getType = () => {
        if (type === 'password') {
            return showPassword ? 'text' : 'password';
        }

        return type;
    }

    const getClassName = () => {
        return className ? className + ' relative flex flex-col' : 'relative flex flex-col';
    }

    return (
        <div className={getClassName()}>
            <div className='relative'>
                {icon &&
                    <div className="absolute flex items-center h-full left-4 z-[11]">
                        <div>
                            {icon}
                        </div>
                    </div>
                }
                {type === 'password' &&
                    <div className="absolute flex items-center h-full right-4 z-[11]">
                        <div className="cursor-pointer" onClick={() => setShowPassword(!showPassword)}>
                            {showPassword
                                ?
                                <EyeSlashIcon
                                    width={20}
                                    color={colors.silver400}
                                />
                                :
                                <EyeIcon
                                    width={20}
                                    color={colors.silver400}
                                />
                            }
                        </div>
                    </div>
                }
                <input
                    className={`w-full p-3 ${icon ? 'px-12' : 'px-4'} rounded-3xl text-gray-900 drop-shadow-sm focus:drop-shadow-none
                    placeholder:silver600 ${isValid ? "border-silver200 focus:border-indigo-200"
                            : "border-red-400 focus:border-red-400"} border-[1.4px] outline-0`}
                    placeholder={placeholder}
                    name={name}
                    onChange={onChange}
                    disabled={disabled}
                    onBlur={onBlur}
                    onKeyDown={onKeyDown}
                    type={getType()}
                    value={value}
                />
            </div>
            <div>
                {errorMessage && <span className='text-sm text-red-600'>{errorMessage}</span>}
                {infoMessage && <span className='text-sm text-orange-400'>{infoMessage}</span>}
                {successMessage && <span className='text-sm text-green-700'>{successMessage}</span>}
            </div>
        </div>
    );
}

export default InputField;