import { useContext, useState } from "react";
import PublicLayout from "../../components/layouts/PublicLayout";
import PasswordField from "../../components/forms/PasswordField";
import EmailField from "../../components/forms/EmailField";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { changeField, getFirstError, isValid } from "../../shared/validate";
import { useNavigate } from "react-router-dom";
import sessionApi from "../../services/apis/sessionApi";
import { isSuccess } from "../../shared/util";
import { AuthContext } from "../../store/AuthContext";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const LogIn = () => {
    const [email, setEmail] = useState({
        value: '',
        isValid: false,
        isChanged: false,
        showValidation: false,
        validators: [
            {
                type: 'required',
                message: 'Email address is required.',
                isValid: true,
            },
            {
                type: 'email',
                message: 'Please provide a valid email address.',
                isValid: true,
            }
        ],
    });

    const [password, setPassword] = useState({
        value: '',
        isValid: false,
        isChanged: false,
        showValidation: false,
        validators: [
            {
                type: 'required',
                message: 'Password is required.',
                isValid: true,
            },
        ]
    });

    const [loginInProgress, setLoginInProgress] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [error, setError] = useState(false);

    const navigate = useNavigate();
    const authContext = useContext(AuthContext);

    const sessionService = sessionApi();

    const handleLoginButtonClick = async () => {
        setLoginInProgress(true);
        setErrorMessages([]);
        setError(false);

        const createSessionResponse = await sessionService.post({
            email: email.value,
            password: password.value,
        });

        if (!isSuccess(createSessionResponse.status)) {
            const errorMessage = createSessionResponse.data && createSessionResponse.data.message ? createSessionResponse.data.message
                : 'Something went wrong. Please contact system administrator.';

            setError(true);
            setErrorMessages([{ message: errorMessage }]);
            setLoginInProgress(false);
            return;
        }

        authContext.login(createSessionResponse.data.token.access_token);

        setLoginInProgress(false);
        navigate('/dashboard');
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
    }

    const isFormValid = () => {
        return isValid([email, password]);
    }

    return (
        <PublicLayout>
            <form className="text-start" onSubmit={onFormSubmit}>
                <div className="flex flex-col mb-8 md:space-y-16">
                    <Link to="/" className="flex items-center mb-12 space-x-2">
                        <img src="/logo.svg" alt="Dinaric Logo" />
                        <span className="font-sans text-4xl font-normal">
                            Elevation
                        </span>
                    </Link>
                    <div>
                        <h2 className="font-sans text-4xl font-normal text-brand">Log in</h2>
                    </div>
                </div>
                <div className="flex flex-col w-full mb-6 space-y-6 sm:w-full md:w-4/5 xl:w-4/5">
                    <EmailField
                        name='email'
                        placeholder='Email'
                        value={email.value}
                        isValid={email.isValid || !email.showValidation}
                        onChange={(e) => setEmail(changeField(e, { ...email }))}
                        onBlur={(e) => setEmail({ ...email, showValidation: true })}
                        errorMessage={email.showValidation ? getFirstError(email) : ''}
                    />
                    <PasswordField
                        name='password'
                        placeholder='Password'
                        value={password.value}
                        isValid={password.isValid || !password.showValidation}
                        onChange={(e) => setPassword(changeField(e, { ...password }))}
                        onBlur={(e) => setPassword({ ...password, showValidation: true })}
                        errorMessage={password.showValidation ? getFirstError(password) : ''}
                    />
                </div>
                <div className="w-full md:w-2/3 xl:w-4/5">
                    <PrimaryButton
                        text="Log in"
                        spinning={loginInProgress}
                        onClick={handleLoginButtonClick}
                        disabled={!isFormValid()}
                        type="submit"
                    />
                </div>
                <div className="flex flex-col pl-2 mt-4 space-y-1">
                    {error &&
                        <span className="font-sans text-red-600">Email and password incorrect</span>
                    }
                </div>
            </form>
        </PublicLayout>
    );
}

export default LogIn;