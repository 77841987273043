import { LockClosedIcon } from "@heroicons/react/24/outline";
import InputField from "./InputField";
import colors from "../../shared/colors";

const PasswordField = (props) => {
    return <InputField
        type='password'
        {...props}
    />
}

export default PasswordField;