import SidebarLayout from "../../components/layouts/SidebarLayout";
import { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import 'react-perfect-scrollbar/dist/css/styles.css';

const mockConversations = [
    { id: 1, title: 'Conversation 1', messages: Array.from({ length: 17 }, (_, i) => ({ id: i + 1, text: `Message ${i + 1} from Conversation 1`, timestamp: `10:${i < 10 ? '0' : ''}${i} AM` })) },
    { id: 2, title: 'Conversation 2', messages: Array.from({ length: 17 }, (_, i) => ({ id: i + 1, text: `Message ${i + 1} from Conversation 2`, timestamp: `11:${i < 10 ? '0' : ''}${i} AM` })) },
    { id: 3, title: 'Conversation 3', messages: Array.from({ length: 17 }, (_, i) => ({ id: i + 1, text: `Message ${i + 1} from Conversation 3`, timestamp: `12:${i < 10 ? '0' : ''}${i} PM` })) },
    { id: 4, title: 'Conversation 4', messages: Array.from({ length: 17 }, (_, i) => ({ id: i + 1, text: `Message ${i + 1} from Conversation 4`, timestamp: `01:${i < 10 ? '0' : ''}${i} PM` })) },
    { id: 5, title: 'Conversation 5', messages: Array.from({ length: 17 }, (_, i) => ({ id: i + 1, text: `Message ${i + 1} from Conversation 5`, timestamp: `02:${i < 10 ? '0' : ''}${i} PM` })) },
    { id: 6, title: 'Conversation 6', messages: Array.from({ length: 17 }, (_, i) => ({ id: i + 1, text: `Message ${i + 1} from Conversation 6`, timestamp: `03:${i < 10 ? '0' : ''}${i} PM` })) },
    { id: 7, title: 'Conversation 7', messages: Array.from({ length: 17 }, (_, i) => ({ id: i + 1, text: `Message ${i + 1} from Conversation 7`, timestamp: `04:${i < 10 ? '0' : ''}${i} PM` })) },
    { id: 8, title: 'Conversation 8', messages: Array.from({ length: 17 }, (_, i) => ({ id: i + 1, text: `Message ${i + 1} from Conversation 8`, timestamp: `05:${i < 10 ? '0' : ''}${i} PM` })) },
    { id: 9, title: 'Conversation 9', messages: Array.from({ length: 17 }, (_, i) => ({ id: i + 1, text: `Message ${i + 1} from Conversation 9`, timestamp: `06:${i < 10 ? '0' : ''}${i} PM` })) },
    { id: 10, title: 'Conversation 10', messages: Array.from({ length: 17 }, (_, i) => ({ id: i + 1, text: `Message ${i + 1} from Conversation 10`, timestamp: `07:${i < 10 ? '0' : ''}${i} PM` })) },
    { id: 11, title: 'Conversation 11', messages: Array.from({ length: 17 }, (_, i) => ({ id: i + 1, text: `Message ${i + 1} from Conversation 11`, timestamp: `08:${i < 10 ? '0' : ''}${i} PM` })) },
    { id: 12, title: 'Conversation 12', messages: Array.from({ length: 17 }, (_, i) => ({ id: i + 1, text: `Message ${i + 1} from Conversation 12`, timestamp: `09:${i < 10 ? '0' : ''}${i} PM` })) },
    { id: 13, title: 'Conversation 13', messages: Array.from({ length: 17 }, (_, i) => ({ id: i + 1, text: `Message ${i + 1} from Conversation 13`, timestamp: `10:${i < 10 ? '0' : ''}${i} PM` })) },
    { id: 14, title: 'Conversation 14', messages: Array.from({ length: 17 }, (_, i) => ({ id: i + 1, text: `Message ${i + 1} from Conversation 14`, timestamp: `11:${i < 10 ? '0' : ''}${i} PM` })) },
    { id: 15, title: 'Conversation 15', messages: Array.from({ length: 17 }, (_, i) => ({ id: i + 1, text: `Message ${i + 1} from Conversation 15`, timestamp: `12:${i < 10 ? '0' : ''}${i} AM` })) },
];

const Conversations = () => {
    const [conversations] = useState(mockConversations);
    const [selectedConversation, setSelectedConversation] = useState(null);

    const handleConversationClick = (conversation) => {
        setSelectedConversation(conversation);
    };

    return (
        <SidebarLayout title="Conversations">
            <div className="flex h-screen">
                {/* Conversations List */}
                <div className="w-1/3 h-full p-4 border-r border-gray-300">
                    <h2 className="mb-4 text-lg font-bold">Conversations</h2>
                    <PerfectScrollbar>
                        <ul>
                            {conversations.map((conversation) => (
                                <li
                                    key={conversation.id}
                                    onClick={() => handleConversationClick(conversation)}
                                    className={`p-2 cursor-pointer rounded ${selectedConversation?.id === conversation.id ? 'bg-gray-200' : 'hover:bg-gray-200'}`}
                                >
                                    {conversation.title}
                                </li>
                            ))}
                        </ul>
                    </PerfectScrollbar>
                </div>

                {/* Messages Display */}
                <div className="w-2/3 h-full p-4">
                    {selectedConversation ? (
                        <>
                            <h2 className="mb-4 text-lg font-bold">{selectedConversation.title}</h2>
                            <PerfectScrollbar>
                                <ul>
                                    {selectedConversation.messages.map((message) => (
                                        <li key={message.id} className="mb-2">
                                            <div className="p-2 bg-gray-100 rounded">
                                                <p>{message.text}</p>
                                                <span className="text-sm text-gray-500">{message.timestamp}</span>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </PerfectScrollbar>
                        </>
                    ) : (
                        <p>Select a conversation to see the messages</p>
                    )}
                </div>
            </div>
        </SidebarLayout>
    );
}

export default Conversations;
